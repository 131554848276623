import { defineStore } from 'pinia';
import { initiateVideo, endSession, sessionRequest, cancel, performerTimedOut, clientSeen } from '@/api/session';
import { useUserStore } from './user';
import { usePerformerStore } from './performer';
import notifications, { type VideoChatUpdate } from '@/socket';
import { usePaymentStore } from './payment';
import type { Stream, SessionType } from '@/ontology/stream';
import { among, match, minutes, seconds, sleep } from '@/utils';

export type Status =
    | 'idle' //nothing happens
    | 'initiating' //fetching the stream parameters
    | 'initializing' //starting the stream
    | 'limbo'
    | 'active' // stream is active, payment begins
    | 'ending'
    | 'patching'
    | 'ended'
    | 'error'
    | 'will-destroy'
    | 'destroying';

interface State {
    status: Status;
    endReason: string;
    playStream?: Stream;
    //performer ID
    performer: number;
    type: 'VOYEUR' | 'VOYEURPEEK';
    switching: boolean;
    timeOuts: {
        ended: any;
        ending: any;
    };
    //notifications this store is subscribed to.
    //onDestroy will stop listening for them
    subscriptions: number[];
}

export const useTeaserVideoStore = (performer: number) =>
    defineStore({
        id: `teaser-${performer}`,
        state: (): State => ({
            status: 'idle',
            endReason: '',
            playStream: undefined,
            performer,
            type: 'VOYEURPEEK',
            switching: false,
            timeOuts: {
                ended: -1,
                ending: -1
            },
            subscriptions: []
        }),
        actions: {
            initialize() {
                this.subscriptions = [notifications.subscribe('videoChat', this.handleNotification), notifications.subscribe('clientstream', this.handleNotification), notifications.subscribe('disconnected', this.handleNotification), notifications.subscribe('voyeur', this.handleNotification)];
            },

            handleNotification(update: VideoChatUpdate) {
                if (this.performer != update.performerId) {
                    return;
                }

                // console.log(`teaser ${performer} notification:`);
                // console.log(update);

                //translates a socket message to an action
                //Rules are checked from top to bottom
                const rules = [
                    {
                        when: { type: 'RESPONSE', message: 'CLICK' },
                        do: () => this.ended('CLICK')
                    },
                    {
                        when: { type: 'RESPONSE', message: 'DISCONNECT' },
                        do: () => this.ended('DISCONNECT')
                    },
                    {
                        when: { type: 'RESPONSE', message: 'MAIN_ENDED' },
                        do: () => this.ended('MAIN_ENDED')
                    }
                ];

                const toMatch = { ...update, ...{ inStatus: this.status } };

                const rule = rules.find(check => match(toMatch, check.when));
                if (rule && rule.do) {
                    rule.do();
                }
            },

            async initiate(type: 'VOYEUR' | 'VOYEURPEEK') {
                if (!this.performer) {
                    return;
                }
                this.initialize();

                const account = useUserStore().account;

                this.setStatus('initiating');

                const performers = usePerformerStore();
                let performer = performers.getById(this.performer);
                if (!performer) {
                    //we'll need to load the performer by id...
                    performer = await performers.loadPerformerById(this.performer);
                }

                const { error: initiateError, result } = await initiateVideo({
                    advert: performer.advertNumber,
                    clientId: account.id!,
                    performerId: this.performer,
                    type
                });

                if (initiateError) {
                    this.setStatus('error');
                    return initiateError;
                }

                if (!result) {
                    throw new Error('Impossible');
                }

                this.setStatus('initializing');
                this.$patch({
                    type,
                    playStream: {
                        id: result.id || result.playToken,
                        name: result.playStream,
                        token: result.playToken,
                        server: result.playWowza,
                        status: 'idle',
                        attempt: 1
                    }
                });
            },

            //called when this user ends the session
            async end() {
                //why end a session that is already end(ed)(ing)?
                if (['ending', 'ended'].includes(this.status)) {
                    return;
                }

                this.setStatus('ending');
                const { error } = await endSession({
                    clientId: useUserStore().account.id!,
                    performerId: this.performer,
                    type: this.type
                });
                if (error) {
                    //TODO: handle this nearly impossible error; probably an error cause the end request was after
                    // the session was ended already?
                }
                this.endReason = 'CLICK';
                this.setStatus('ended');
            },

            //called when the system tells this client the session has ended, mostly in response to a websocket notification
            async ended(reason: string) {
                this.endReason = reason;
                this.setStatus('ended');
            },

            async destroy(wait = 0) {
                if (wait) {
                    this.setStatus('will-destroy');
                    await sleep(wait);
                }

                if (this.status != 'error') {
                    await this.end();
                }
                this.setStatus('destroying');
                clearTimeout(this.timeOuts.ending);
                clearTimeout(this.timeOuts.ended);
                this.playStream = undefined;

                this.subscriptions.forEach(id => notifications.unsubscribe(id));
                this.$dispose();
            },

            setStatus(value: Status) {
                if (this.status == value) {
                    return;
                }

                this.status = value;
                notifications.sendLocalEvent('status_change', {
                    sender: 'teaserVideo',
                    value,
                    performer: this.performer
                });

                if (this.status == 'ending') {
                    this.timeOuts.ending = setTimeout(() => {
                        if (this.status != 'ending') return;
                        this.ended('DISCONNECTED');
                    }, seconds(1));
                }
                if (this.status == 'ended') {
                    this.timeOuts.ended = setTimeout(() => {
                        if (this.status != 'ended') return;
                        this.destroy();
                    }, seconds(0.5));
                }
            },

            playStreamStatusChange(newValue: string) {
                if (!this.playStream) {
                    //the stream is reset before a final status message can come in for the stream
                    return;
                }

                //console.log(`stream status van ${this.stream.status} naar ${newValue}`)
                this.playStream.status = newValue;
                //I don't care what the new status of a stream is, if this session is terminating already
                if (['ending', 'ended'].includes(this.status)) {
                    return;
                }

                if (newValue == 'error') {
                    this.ended('start-error');
                }

                if (newValue == 'limbo') {
                    this.setStatus('limbo');
                }

                //I don't care about intermittent statuses between initializing and active,
                if (newValue == 'active') {
                    this.setStatus('active');
                    //"really" activate that session
                    notifications.sendEvent({
                        receiverType: 'ROLE_PERFORMER',
                        receiverId: this.performer,
                        event: 'videoChat',
                        content: {
                            type: 'START_TIMER_DEVICE',
                            clientId: useUserStore().account.id!,
                            performerId: this.performer,
                            value: undefined
                        }
                    });
                }

                if (['destroying', 'destroying_janus'].includes(newValue) && !this.switching) {
                    //this message is usually received before the real reason is communicated through the socket server
                    //so we put the status to 'ending', the status that times out to 'ended' after 1 second
                    this.setStatus('ending');
                }
            }
        }
    })();
