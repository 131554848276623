<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useLocalizationStore } from '@/stores/localization';
import { useUserStore } from '@/stores/user';
import { useModalsStore } from '@/stores/modals';
import { storeToRefs } from 'pinia';
import config from '@/config';
import { useRouter, useRoute } from 'vue-router';
import { usePerformerStore } from '@/stores/performer';
import { usePurchaseStore } from '@/stores/purchase';
import Back from './back/back.vue';
import Search from './search/search.vue';
import Usermenu from './usermenu/usermenu.vue';
import Catmenu from './catmenu/catmenu.vue';
import Tagmenu from './filters/tags/filtertag.vue';
import Filters from './filters/filters.vue';
import type { Language } from '@/ontology/i18n';
import router from '@/router';
import { useFilterStore } from '@/stores/filter';
import { asInt, getStaticImages } from '@/utils';
import { DEFAULT_LIMIT } from '@/api/performer/request';

const route = useRoute();
const { initialize } = useLocalizationStore();
const { clearFilters } = useFilterStore();
let searchInput = ref('');
let categoriesOrder = ref(1);

const { categories, numbers, language, languages, marketing } = storeToRefs(useLocalizationStore());
const { account, status, userMenu, catMenu, filterMenu } = storeToRefs(useUserStore());
const { role, isLoggedIn, toggleUserMenu, toggleCatMenu, toggleFilterMenu, closeMenus } = useUserStore();
const userStore = useUserStore();

const { fees } = storeToRefs(usePurchaseStore());
const { toggleModal } = useModalsStore();
const theme = ref(import.meta.env.VITE_Themename);

const props = withDefaults(
    defineProps<{
        offset?: number;
        limit?: number;
        page?: number;
        search?: string;
        category?: string;
        services?: string;
        ages?: string;
    }>(),
    { offset: 0, limit: DEFAULT_LIMIT, page: 1, search: undefined, category:  undefined, services: undefined, ages: undefined }
);

const store = usePerformerStore();

const filterCategories = computed(() => {
    return categories!.value!.filter(cat => cat.order === categoriesOrder.value);
});

const showHeader = computed(() => {
    return route.name != 'teaser';
});

const showFees = computed(() => {
    //clone because or vue warning
    const feesCloned = [...fees.value];
    return feesCloned.reverse();
});

const showBack = computed(() => {
    if (config.Themename && config.Themename === 'thuis') {
        const pages = ['grid', 'favourites'];
        return pages.includes(route.name as string);
    } else {
        return false;
    }

});

function search() {
    // TODO: search favorites
    router.push({
        name: 'grid',
        params: { category: route.params.category },
        query: { search: searchInput.value }
    });
    searchInput.value = '';
}

async function setLang(lang: Language) {
    await initialize(lang);
    store.resetFetched('grid');
    store.loadSlice('grid', { ...props });
}

function t(inp: string) {
    return inp;
}

function clickUserMenu(){
    if(!isLoggedIn()){
        toggleModal('login');
        return;
    }

    toggleUserMenu();
}
</script>

<template src="./header.html"></template>

<style lang="scss">
@import './header.scss';
</style>
